import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const IDCardUpload: React.FC<{ vendorId: number }> = ({ vendorId }) => {
    const [preview, setPreview] = useState<string | null>(null);
    const [fileInfo, setFileInfo] = useState<{ name: string; type: string } | null>(null);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];

            setFileInfo({ name: file.name, type: file.type });

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [vendorId]);

    const handleUpload = () => {
        alert('신분증이 성공적으로 업로드되었습니다.');
        // vendorId 와 파일을 API 통해서 서버로 보내며 업로드 
    };

    const handleCancel = () => {
        setPreview(null);
        setFileInfo(null);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'application/pdf': ['.pdf'],
        },
        multiple: false,
    });

    return (
        <div className="w-full">
            <label className="block font-semibold mb-2">신분증</label>
            <div
                {...getRootProps()}
                className="p-4 border-2 border-dashed border-gray-300 rounded-md text-center cursor-pointer"
            >
                <input {...getInputProps()} />
                <div className="flex flex-col justify-center items-center h-48 text-gray-500">
                    {preview ? (
                        <img src={preview} alt="미리보기" className="max-h-full max-w-full object-contain" />
                    ) : (
                        <>
                            <p>파일을 드래그하거나 클릭하여 신분증을 업로드하세요.</p>
                            <em>(JPEG, PNG, PDF 형식만 가능합니다)</em>
                        </>
                    )}
                </div>
            </div>
            {fileInfo && (
                <div className="mt-4 text-gray-700 text-center">
                    <p><strong>파일 이름:</strong> {fileInfo.name}</p>
                    <p><strong>파일 형식:</strong> {fileInfo.type}</p>
                </div>
            )}
            {fileInfo && (
                <div className="flex justify-center space-x-4 mt-4">
                    <button
                        onClick={handleUpload}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                    >
                        업로드
                    </button>
                    <button
                        onClick={handleCancel}
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                    >
                        초기화
                    </button>
                </div>
            )}
        </div>
    );
};

export default IDCardUpload;
