import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
    return (
        <div>
            <nav className="bg-gray-800 p-2 roundedt-lg flex justify-around items-center">
                <Link to="/">
                    <img src={"/ok114.png"} alt="로고" className="w-24 h-10" />
                </Link>
                <Link to="/company-approval" className="text-white font-semibold hover:bg-gray-700 px-2 py-1 rounded-md transition duration-300">
                    업체 승인 관리
                </Link>
                <Link to="/company-sales" className="text-white font-semibold hover:bg-gray-700 px-2 py-1 rounded-md transition duration-300">
                    업체별 매출 통계
                </Link>
                <Link to="/subpage3" className="text-white font-semibold hover:bg-gray-700 px-2 py-1 rounded-md transition duration-300">
                    서브페이지 3
                </Link>
                <Link to="/subpage4" className="text-white font-semibold hover:bg-gray-700 px-2 py-1 rounded-md transition duration-300">
                    서브페이지 4
                </Link>
                <Link to="/subpage5" className="text-white font-semibold hover:bg-gray-700 px-2 py-1 rounded-md transition duration-300">
                    서브페이지 5
                </Link>
            </nav>
        </div>
    );
};

export default Navbar;
