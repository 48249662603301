import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { vendors } from '../../Example/ExampleCompany'; // 기존 업체 목록
import { vendorSalesData } from '../../Example/ExampleSalesData';
import Navbar from '../../nav/Navbar';

const CompanySales: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState<'name' | 'ownerName'>('name');
    const [sortCriteria, setSortCriteria] = useState<'todaySalesAsc' | 'todaySalesDesc' | 'recentDailySalesAsc' | 'recentDailySalesDesc' | 'monthlySalesAsc' | 'monthlySalesDesc' | 'registrationDateAsc' | 'registrationDateDesc'>('todaySalesDesc');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchField(event.target.value as 'name' | 'ownerName');
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortCriteria(event.target.value as 'todaySalesAsc' | 'todaySalesDesc' | 'recentDailySalesAsc' | 'recentDailySalesDesc' | 'monthlySalesAsc' | 'monthlySalesDesc' | 'registrationDateAsc' | 'registrationDateDesc');
    };

    const filteredVendors = vendors.filter((vendor) =>
        searchField === 'name'
            ? vendor.name.toLowerCase().includes(searchTerm.toLowerCase())
            : vendor.ownerName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedVendors = filteredVendors.sort((a, b) => {
        const vendorSalesA = vendorSalesData.find(sales => sales.id === a.id);
        const vendorSalesB = vendorSalesData.find(sales => sales.id === b.id);

        const today = new Date().toISOString().split('T')[0];
        const currentMonth = new Date().getMonth() + 1;

        const todaySalesA = vendorSalesA?.salesData.find(sales => sales.date === today)?.dailySales || 0;
        const todaySalesB = vendorSalesB?.salesData.find(sales => sales.date === today)?.dailySales || 0;

        const monthlySalesA = vendorSalesA?.salesData
            .filter(sales => new Date(sales.date).getMonth() + 1 === currentMonth)
            .reduce((acc, curr) => acc + curr.dailySales, 0) || 0;

        const monthlySalesB = vendorSalesB?.salesData
            .filter(sales => new Date(sales.date).getMonth() + 1 === currentMonth)
            .reduce((acc, curr) => acc + curr.dailySales, 0) || 0;

        const recentDailySalesA = vendorSalesA?.salesData[vendorSalesA.salesData.length - 1]?.dailySales || 0;
        const recentDailySalesB = vendorSalesB?.salesData[vendorSalesB.salesData.length - 1]?.dailySales || 0;

        const registrationDateA = new Date(a.registrationDate).getTime();
        const registrationDateB = new Date(b.registrationDate).getTime();

        let comparison = 0;
        if (sortCriteria.includes('todaySales')) {
            comparison = todaySalesA - todaySalesB;
        } else if (sortCriteria.includes('recentDailySales')) {
            comparison = recentDailySalesA - recentDailySalesB;
        } else if (sortCriteria.includes('monthlySales')) {
            comparison = monthlySalesA - monthlySalesB;
        } else if (sortCriteria.includes('registrationDate')) {
            comparison = registrationDateA - registrationDateB;
        }

        return sortCriteria.endsWith('Asc') ? comparison : -comparison;
    });

    return (
        <div className='w-full'>
            <Navbar />
            <div className="max-w-7xl mx-auto p-4">
                <div className="flex flex-col md:flex-row justify-between items-center mb-4 space-y-2 md:space-y-0">
                    <div className="flex space-x-4 w-full">
                        <select
                            value={searchField}
                            onChange={handleSearchFieldChange}
                            className="p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-32"
                        >
                            <option value="name">업체 이름</option>
                            <option value="ownerName">대표자명</option>
                        </select>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={`${searchField === 'name' ? '업체 이름' : '대표자명'} 검색`}
                            className="p-3 border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <div className="flex space-x-4 mt-2 md:mt-0 justify-end">
                            <select
                                value={sortCriteria}
                                onChange={handleSortChange}
                                className="p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-44"
                            >
                                <option value="todaySalesAsc">금일 매출순 &#8593;</option>
                                <option value="todaySalesDesc">금일 매출순 &#8595;</option>
                                <option value="recentDailySalesAsc">최근 일매출순 &#8593;</option>
                                <option value="recentDailySalesDesc">최근 일매출순 &#8595;</option>
                                <option value="monthlySalesAsc">이번 달 매출순 &#8593;</option>
                                <option value="monthlySalesDesc">이번 달 매출순 &#8595;</option>
                                <option value="registrationDateAsc">가입순 &#8593;</option>
                                <option value="registrationDateDesc">가입순 &#8595;</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {sortedVendors.map((vendor) => {
                        const vendorSales = vendorSalesData.find(sales => sales.id === vendor.id);

                        const today = new Date().toISOString().split('T')[0];
                        const currentMonth = new Date().getMonth() + 1;

                        const todaySales = vendorSales?.salesData.find(sales => sales.date === today)?.dailySales || 0;
                        const monthlySales = vendorSales?.salesData
                            .filter(sales => new Date(sales.date).getMonth() + 1 === currentMonth)
                            .reduce((acc, curr) => acc + curr.dailySales, 0) || 0;
                        const recentDailySales = vendorSales?.salesData[vendorSales.salesData.length - 1]?.dailySales || 0;

                        return (
                            <Link
                                key={vendor.id}
                                to={`/company-sales/${vendor.id}`}
                                className="block p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300"
                            >
                                <h2 className="text-2xl font-semibold mb-4 text-gray-900">{vendor.name}</h2>
                                <p className="text-gray-600">사업자 등록번호: {vendor.businessNumber}</p>
                                <p className="text-gray-600">대표자명: {vendor.ownerName}</p>
                                {vendorSales && vendorSales.salesData.length > 0 && (
                                    <div className="mt-4">
                                        <p className="text-gray-800"><span className="font-semibold">금일 매출:</span> {todaySales.toLocaleString()}원</p>
                                        <p className="text-gray-800"><span className="font-semibold">최근 일매출:</span> {recentDailySales.toLocaleString()}원</p>
                                        <p className="text-gray-800"><span className="font-semibold">이번 달 매출:</span> {monthlySales.toLocaleString()}원</p>
                                    </div>
                                )}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default CompanySales;
