import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../nav/Navbar';
import { vendors } from '../../Example/ExampleCompany';
import { vendorDocuments } from '../../Example/ExampleCompanyApproval';

const CompanyApproval: React.FC = () => {
    const [activeTab, setActiveTab] = useState("미승인");

    // 탭에 따라 필터링된 업체 리스트 반환
    const filteredVendors = vendors.filter(vendor => vendor.status === activeTab);

    // 각 업체의 서류 상태를 반환하는 함수
    const getDocumentStatus = (vendorId: number) => {
        const document = vendorDocuments.find(doc => doc.vendorId === vendorId);
        return {
            businessLicense: document?.businessLicense !== null,
            idCard: document?.idCard !== null,
            bankStatement: document?.bankStatement !== null
        };
    };

    return (
        <div className="w-full">
            <Navbar />
            <div className="max-w-4xl mx-auto p-4">
                <div className="mb-10">
                    <nav className="flex space-x-4">
                        <button
                            onClick={() => setActiveTab("미승인")}
                            className={`px-4 py-2 rounded-lg ${activeTab === "미승인" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} hover:bg-blue-600 hover:text-white`}
                        >
                            미승인
                        </button>
                        <button
                            onClick={() => setActiveTab("승인 대기")}
                            className={`px-4 py-2 rounded-lg ${activeTab === "승인 대기" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} hover:bg-blue-600 hover:text-white`}
                        >
                            승인 대기
                        </button>
                        <button
                            onClick={() => setActiveTab("승인")}
                            className={`px-4 py-2 rounded-lg ${activeTab === "승인" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} hover:bg-blue-600 hover:text-white`}
                        >
                            승인
                        </button>
                        <button
                            onClick={() => setActiveTab("해지")}
                            className={`px-4 py-2 rounded-lg ${activeTab === "해지" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"} hover:bg-blue-600 hover:text-white`}
                        >
                            해지
                        </button>
                    </nav>
                </div>

                <div className="space-y-6">
                    {filteredVendors.length > 0 ? (
                        filteredVendors.map(vendor => {
                            const docStatus = getDocumentStatus(vendor.id);
                            return (
                                <div key={vendor.id} className="p-4 bg-white rounded-lg shadow">
                                    <div className="flex items-center mb-2">
                                        <h2 className="text-2xl font-semibold">{vendor.name}</h2>
                                        {vendor.status !== "해지" && vendor.status !== "승인" && (
                                            <div className="flex space-x-2 ml-4">
                                                <div className={`w-4 h-4 rounded-full ${docStatus.businessLicense ? "bg-green-500" : "bg-gray-300"}`}></div>
                                                <div className={`w-4 h-4 rounded-full ${docStatus.idCard ? "bg-green-500" : "bg-gray-300"}`}></div>
                                                <div className={`w-4 h-4 rounded-full ${docStatus.bankStatement ? "bg-green-500" : "bg-gray-300"}`}></div>
                                            </div>
                                        )}
                                    </div>
                                    <p>사업자 등록번호: {vendor.businessNumber}</p>
                                    <p>대표자명: {vendor.ownerName}</p>
                                    <p>상태:
                                        {vendor.status === "미승인" && <span className="text-red-500 ml-2">미승인</span>}
                                        {vendor.status === "승인 대기" && <span className="text-yellow-500 ml-2">승인 대기</span>}
                                        {vendor.status === "승인" && <span className="text-green-500 ml-2">승인</span>}
                                        {vendor.status === "해지" && <span className="text-gray-500 ml-2">해지</span>}
                                    </p>

                                    <div className="flex space-x-4 mt-4">
                                        <Link
                                            to={`/company-approval/${vendor.id}`}
                                            className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md"
                                        >
                                            상세 정보 수정
                                        </Link>
                                        {(vendor.status === "미승인" || vendor.status === "승인 대기") && (
                                            <Link
                                                to={`/company-approval/${vendor.id}/upload`}
                                                className="inline-block bg-green-500 text-white px-4 py-2 rounded-md"
                                            >
                                                필수 서류 업로드
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <p>해당 상태의 업체가 없습니다.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompanyApproval;
