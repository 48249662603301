export interface Vendor {
    id: number;
    name: string;
    businessNumber: string;
    ownerName: string;
    status: string; // "미승인", "승인", "승인 대기", "해지"
    address: string;
    contactNumber: string;
    email: string;
    accountNumber: string;
    industryType: string;
    registrationDate: string;
    approvalDate?: string; // 선택적 필드
    terminationDate?: string; // 선택적 필드
    onlineStatus: boolean; // true = 온라인, false = 오프라인
    grade: string; // 업체 등급
    lastSettlementDate?: string; // 마지막 정산 날짜 (선택적 필드)
}

export interface VendorGrade {
    grade: string;
    commissionRate: number; // 수수료율 (%)
}

// 등급별 수수료율
export const vendorGrades: VendorGrade[] = [
    { grade: "VIP", commissionRate: 1 },
    { grade: "A", commissionRate: 5 },
    { grade: "B", commissionRate: 10 },
    { grade: "C", commissionRate: 15 },
    { grade: "D", commissionRate: 20 },
];

export const vendors: Vendor[] = [
    {
        id: 1,
        name: "서울정통설렁탕",
        businessNumber: "123-45-67890",
        ownerName: "홍길동",
        status: "미승인",
        address: "서울특별시 강남구 1번지",
        contactNumber: "010-1234-5678",
        email: "owner1@example.com",
        accountNumber: "123-456-789",
        industryType: "한식당",
        registrationDate: "2024-01-01",
        onlineStatus: true,
        grade: "A",
        lastSettlementDate: "2024-08-01",
    },
    {
        id: 2,
        name: "홍대떡볶이",
        businessNumber: "223-45-67890",
        ownerName: "김철수",
        status: "승인 대기",
        address: "서울특별시 서초구 2번지",
        contactNumber: "010-2345-6789",
        email: "owner2@example.com",
        accountNumber: "223-456-789",
        industryType: "분식집",
        registrationDate: "2024-02-01",
        approvalDate: "2024-03-01",
        onlineStatus: false,
        grade: "B",
        lastSettlementDate: "2024-08-02",
    },
    {
        id: 3,
        name: "이영희 고기집",
        businessNumber: "323-45-67890",
        ownerName: "이영희",
        status: "승인",
        address: "서울특별시 송파구 3번지",
        contactNumber: "010-3456-7890",
        email: "owner3@example.com",
        accountNumber: "323-456-789",
        industryType: "고깃집",
        registrationDate: "2024-03-01",
        approvalDate: "2024-04-01",
        onlineStatus: true,
        grade: "VIP",
        lastSettlementDate: "2024-08-03",
    },
    {
        id: 4,
        name: "청담해물찜",
        businessNumber: "423-45-67890",
        ownerName: "박영수",
        status: "해지",
        address: "서울특별시 강동구 4번지",
        contactNumber: "010-4567-8901",
        email: "owner4@example.com",
        accountNumber: "423-456-789",
        industryType: "해물찜",
        registrationDate: "2024-04-01",
        approvalDate: "2024-05-01",
        terminationDate: "2024-06-01",
        onlineStatus: false,
        grade: "C",
        lastSettlementDate: "2024-08-04",
    },
    {
        id: 5,
        name: "마포돼지갈비",
        businessNumber: "523-45-67890",
        ownerName: "최영민",
        status: "미승인",
        address: "서울특별시 마포구 5번지",
        contactNumber: "010-5678-9012",
        email: "owner5@example.com",
        accountNumber: "523-456-789",
        industryType: "고깃집",
        registrationDate: "2024-05-01",
        onlineStatus: true,
        grade: "B",
        lastSettlementDate: "2024-08-05",
    },
    {
        id: 6,
        name: "용산불고기",
        businessNumber: "623-45-67890",
        ownerName: "한수연",
        status: "승인 대기",
        address: "서울특별시 용산구 6번지",
        contactNumber: "010-6789-0123",
        email: "owner6@example.com",
        accountNumber: "623-456-789",
        industryType: "한식당",
        registrationDate: "2024-06-01",
        approvalDate: "2024-07-01",
        onlineStatus: false,
        grade: "C",
        lastSettlementDate: "2024-08-06",
    },
    {
        id: 7,
        name: "강북왕갈비",
        businessNumber: "723-45-67890",
        ownerName: "정민호",
        status: "승인",
        address: "서울특별시 강북구 7번지",
        contactNumber: "010-7890-1234",
        email: "owner7@example.com",
        accountNumber: "723-456-789",
        industryType: "고깃집",
        registrationDate: "2024-07-01",
        approvalDate: "2024-08-01",
        onlineStatus: true,
        grade: "A",
        lastSettlementDate: "2024-08-07",
    },
    {
        id: 8,
        name: "은평칼국수",
        businessNumber: "823-45-67890",
        ownerName: "오세훈",
        status: "해지",
        address: "서울특별시 은평구 8번지",
        contactNumber: "010-8901-2345",
        email: "owner8@example.com",
        accountNumber: "823-456-789",
        industryType: "칼국수",
        registrationDate: "2024-08-01",
        approvalDate: "2024-09-01",
        terminationDate: "2024-10-01",
        onlineStatus: false,
        grade: "D",
        lastSettlementDate: "2024-08-08",
    },
    {
        id: 9,
        name: "중구초밥",
        businessNumber: "923-45-67890",
        ownerName: "박서준",
        status: "승인",
        address: "서울특별시 중구 9번지",
        contactNumber: "010-9012-3456",
        email: "owner9@example.com",
        accountNumber: "923-456-789",
        industryType: "일식당",
        registrationDate: "2024-09-01",
        approvalDate: "2024-10-01",
        onlineStatus: true,
        grade: "VIP",
        lastSettlementDate: "2024-08-09",
    },
    {
        id: 10,
        name: "서대문쌀국수",
        businessNumber: "103-45-67890",
        ownerName: "이준기",
        status: "승인 대기",
        address: "서울특별시 서대문구 10번지",
        contactNumber: "010-1234-5678",
        email: "owner10@example.com",
        accountNumber: "103-456-789",
        industryType: "쌀국수",
        registrationDate: "2024-10-01",
        approvalDate: "2024-11-01",
        onlineStatus: false,
        grade: "B",
        lastSettlementDate: "2024-08-10",
    },
    {
        id: 11,
        name: "종로찜닭",
        businessNumber: "113-45-67890",
        ownerName: "김지수",
        status: "해지",
        address: "서울특별시 종로구 11번지",
        contactNumber: "010-2345-6789",
        email: "owner11@example.com",
        accountNumber: "113-456-789",
        industryType: "찜닭",
        registrationDate: "2024-11-01",
        approvalDate: "2024-12-01",
        terminationDate: "2025-01-01",
        onlineStatus: false,
        grade: "C",
        lastSettlementDate: "2024-08-11",
    },
    {
        id: 12,
        name: "동작육회",
        businessNumber: "123-45-67890",
        ownerName: "황정민",
        status: "미승인",
        address: "서울특별시 동작구 12번지",
        contactNumber: "010-3456-7890",
        email: "owner12@example.com",
        accountNumber: "123-456-789",
        industryType: "한식당",
        registrationDate: "2024-12-01",
        onlineStatus: true,
        grade: "A",
        lastSettlementDate: "2024-08-12",
    },
];
