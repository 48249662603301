export interface SalesData {
    date: string; // 일자
    dailySales: number; // 일매출
}

export interface VendorSales {
    id: number; // Vendor ID
    salesData: SalesData[]; // 일별 매출 데이터 배열
    totalSales?: number; // 총 매출액 (선택적)
    totalCommission?: number; // 총 수수료 (선택적)
    totalSettlementAmount?: number; // 총 정산 금액 (선택적)
    totalUnsettledAmount?: number; // 미정산 금액 (선택적)
}

export const vendorSalesData: VendorSales[] = [
    {
        id: 1,
        salesData: [
            { date: "2024-07-01", dailySales: 200000 },
            { date: "2024-07-02", dailySales: 220000 },
            { date: "2024-07-03", dailySales: 210000 },
            { date: "2024-07-04", dailySales: 240000 },
            { date: "2024-07-05", dailySales: 230000 },
            { date: "2024-07-06", dailySales: 250000 },
            { date: "2024-07-07", dailySales: 260000 },
            { date: "2024-07-08", dailySales: 270000 },
            { date: "2024-07-09", dailySales: 280000 },
            { date: "2024-07-10", dailySales: 290000 },
            { date: "2024-07-11", dailySales: 300000 },
            { date: "2024-07-12", dailySales: 310000 },
            { date: "2024-07-13", dailySales: 320000 },
            { date: "2024-07-14", dailySales: 330000 },
            { date: "2024-07-15", dailySales: 340000 },
            { date: "2024-07-16", dailySales: 350000 },
            { date: "2024-07-17", dailySales: 360000 },
            { date: "2024-07-18", dailySales: 370000 },
            { date: "2024-07-19", dailySales: 380000 },
            { date: "2024-07-20", dailySales: 390000 },
            { date: "2024-07-21", dailySales: 400000 },
            { date: "2024-07-22", dailySales: 410000 },
            { date: "2024-07-23", dailySales: 420000 },
            { date: "2024-07-24", dailySales: 430000 },
            { date: "2024-07-25", dailySales: 440000 },
            { date: "2024-07-26", dailySales: 450000 },
            { date: "2024-07-27", dailySales: 460000 },
            { date: "2024-07-28", dailySales: 470000 },
            { date: "2024-07-29", dailySales: 480000 },
            { date: "2024-07-30", dailySales: 490000 },
            { date: "2024-07-31", dailySales: 500000 },
            { date: "2024-08-01", dailySales: 510000 },
            { date: "2024-08-02", dailySales: 520000 },
            { date: "2024-08-03", dailySales: 530000 },
            { date: "2024-08-04", dailySales: 540000 },
            { date: "2024-08-05", dailySales: 550000 },
            { date: "2024-08-06", dailySales: 560000 },
            { date: "2024-08-07", dailySales: 570000 },
            { date: "2024-08-08", dailySales: 580000 },
            { date: "2024-08-09", dailySales: 590000 },
            { date: "2024-08-10", dailySales: 600000 },
            { date: "2024-08-11", dailySales: 610000 },
            { date: "2024-08-12", dailySales: 620000 },
            { date: "2024-08-13", dailySales: 630000 },
            { date: "2024-08-14", dailySales: 640000 },
            { date: "2024-08-15", dailySales: 650000 },
            { date: "2024-08-16", dailySales: 660000 },
            { date: "2024-08-17", dailySales: 670000 },
            { date: "2024-08-18", dailySales: 680000 },
            { date: "2024-08-19", dailySales: 690000 },
            { date: "2024-08-20", dailySales: 700000 },
            { date: "2024-08-21", dailySales: 710000 },
            { date: "2024-08-22", dailySales: 720000 },
            { date: "2024-08-23", dailySales: 730000 },
            { date: "2024-08-24", dailySales: 740000 },
            { date: "2024-08-25", dailySales: 750000 },
            { date: "2024-08-26", dailySales: 760000 },
            { date: "2024-08-27", dailySales: 770000 },
            { date: "2024-08-28", dailySales: 780000 },
            { date: "2024-08-29", dailySales: 790000 },
            { date: "2024-08-30", dailySales: 800000 },
            { date: "2024-08-31", dailySales: 810000 },
        ],
    },
    {
        id: 2,
        salesData: [
            { date: "2024-08-01", dailySales: 150000 },
            { date: "2024-08-02", dailySales: 160000 },
            { date: "2024-08-03", dailySales: 170000 },
            { date: "2024-08-04", dailySales: 165000 },
            { date: "2024-08-05", dailySales: 175000 },
            { date: "2024-08-06", dailySales: 180000 },
            { date: "2024-08-07", dailySales: 185000 },
            { date: "2024-08-08", dailySales: 190000 },
            { date: "2024-08-09", dailySales: 200000 },
            { date: "2024-08-10", dailySales: 210000 },
        ]
    },
    {
        id: 3,
        salesData: [
            { date: "2024-08-01", dailySales: 220000 },
            { date: "2024-08-02", dailySales: 225000 },
            { date: "2024-08-03", dailySales: 230000 },
            { date: "2024-08-04", dailySales: 235000 },
            { date: "2024-08-05", dailySales: 240000 },
            { date: "2024-08-06", dailySales: 245000 },
            { date: "2024-08-07", dailySales: 250000 },
            { date: "2024-08-08", dailySales: 255000 },
            { date: "2024-08-09", dailySales: 260000 },
            { date: "2024-08-10", dailySales: 265000 },
        ]
    },
    {
        id: 4,
        salesData: [
            { date: "2024-08-01", dailySales: 300000 },
            { date: "2024-08-02", dailySales: 310000 },
            { date: "2024-08-03", dailySales: 320000 },
            { date: "2024-08-04", dailySales: 330000 },
            { date: "2024-08-05", dailySales: 340000 },
            { date: "2024-08-06", dailySales: 350000 },
            { date: "2024-08-07", dailySales: 360000 },
            { date: "2024-08-08", dailySales: 370000 },
            { date: "2024-08-09", dailySales: 380000 },
            { date: "2024-08-10", dailySales: 390000 },
        ]
    },
    {
        id: 5,
        salesData: [
            { date: "2024-08-01", dailySales: 180000 },
            { date: "2024-08-02", dailySales: 185000 },
            { date: "2024-08-03", dailySales: 190000 },
            { date: "2024-08-04", dailySales: 195000 },
            { date: "2024-08-05", dailySales: 200000 },
            { date: "2024-08-06", dailySales: 205000 },
            { date: "2024-08-07", dailySales: 210000 },
            { date: "2024-08-08", dailySales: 215000 },
            { date: "2024-08-09", dailySales: 220000 },
            { date: "2024-08-10", dailySales: 225000 },
        ]
    },
    {
        id: 6,
        salesData: [
            { date: "2024-08-01", dailySales: 140000 },
            { date: "2024-08-02", dailySales: 145000 },
            { date: "2024-08-03", dailySales: 150000 },
            { date: "2024-08-04", dailySales: 155000 },
            { date: "2024-08-05", dailySales: 160000 },
            { date: "2024-08-06", dailySales: 165000 },
            { date: "2024-08-07", dailySales: 170000 },
            { date: "2024-08-08", dailySales: 175000 },
            { date: "2024-08-09", dailySales: 180000 },
            { date: "2024-08-10", dailySales: 185000 },
        ]
    },
    {
        id: 7,
        salesData: [
            { date: "2024-08-01", dailySales: 260000 },
            { date: "2024-08-02", dailySales: 270000 },
            { date: "2024-08-03", dailySales: 280000 },
            { date: "2024-08-04", dailySales: 290000 },
            { date: "2024-08-05", dailySales: 300000 },
            { date: "2024-08-06", dailySales: 310000 },
            { date: "2024-08-07", dailySales: 320000 },
            { date: "2024-08-08", dailySales: 330000 },
            { date: "2024-08-09", dailySales: 340000 },
            { date: "2024-08-10", dailySales: 350000 },
        ]
    },
    {
        id: 8,
        salesData: [
            { date: "2024-08-01", dailySales: 120000 },
            { date: "2024-08-02", dailySales: 125000 },
            { date: "2024-08-03", dailySales: 130000 },
            { date: "2024-08-04", dailySales: 135000 },
            { date: "2024-08-05", dailySales: 140000 },
            { date: "2024-08-06", dailySales: 145000 },
            { date: "2024-08-07", dailySales: 150000 },
            { date: "2024-08-08", dailySales: 155000 },
            { date: "2024-08-09", dailySales: 160000 },
            { date: "2024-08-10", dailySales: 165000 },
        ]
    },
    {
        id: 9,
        salesData: [
            { date: "2024-08-01", dailySales: 290000 },
            { date: "2024-08-02", dailySales: 295000 },
            { date: "2024-08-03", dailySales: 300000 },
            { date: "2024-08-04", dailySales: 305000 },
            { date: "2024-08-05", dailySales: 310000 },
            { date: "2024-08-06", dailySales: 315000 },
            { date: "2024-08-07", dailySales: 320000 },
            { date: "2024-08-08", dailySales: 325000 },
            { date: "2024-08-09", dailySales: 330000 },
            { date: "2024-08-10", dailySales: 335000 },
        ]
    },
    {
        id: 10,
        salesData: [
            { date: "2024-08-01", dailySales: 250000 },
            { date: "2024-08-02", dailySales: 255000 },
            { date: "2024-08-03", dailySales: 260000 },
            { date: "2024-08-04", dailySales: 265000 },
            { date: "2024-08-05", dailySales: 270000 },
            { date: "2024-08-06", dailySales: 275000 },
            { date: "2024-08-07", dailySales: 280000 },
            { date: "2024-08-08", dailySales: 285000 },
            { date: "2024-08-09", dailySales: 290000 },
            { date: "2024-08-10", dailySales: 295000 },
        ]
    },
    {
        id: 11,
        salesData: [
            { date: "2024-08-01", dailySales: 230000 },
            { date: "2024-08-02", dailySales: 235000 },
            { date: "2024-08-03", dailySales: 240000 },
            { date: "2024-08-04", dailySales: 245000 },
            { date: "2024-08-05", dailySales: 250000 },
            { date: "2024-08-06", dailySales: 255000 },
            { date: "2024-08-07", dailySales: 260000 },
            { date: "2024-08-08", dailySales: 265000 },
            { date: "2024-08-09", dailySales: 270000 },
            { date: "2024-08-10", dailySales: 275000 },
        ]
    },
    {
        id: 12,
        salesData: [
            { date: "2024-08-01", dailySales: 190000 },
            { date: "2024-08-02", dailySales: 195000 },
            { date: "2024-08-03", dailySales: 200000 },
            { date: "2024-08-04", dailySales: 205000 },
            { date: "2024-08-05", dailySales: 210000 },
            { date: "2024-08-06", dailySales: 215000 },
            { date: "2024-08-07", dailySales: 220000 },
            { date: "2024-08-08", dailySales: 225000 },
            { date: "2024-08-09", dailySales: 230000 },
            { date: "2024-08-10", dailySales: 235000 },
        ]
    },
];
