import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../../nav/Navbar';
import BusinessLicenseUpload from './ApprovalUpload/BusinessLicenseUpload';
import IDCardUpload from './ApprovalUpload/IDCardUpload';
import BankStatementUpload from './ApprovalUpload/BankStatementUpload';

const CompanyApprovalUpload: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('사업자 등록증');

    const handleCancel = () => {
        navigate(`/company-approval`);
    };

    return (
        <div className='w-full'>
            <Navbar />
            <div className="max-w-4xl mx-auto">
                <h1 className="text-3xl font-semibold mb-6 text-center text-gray-800">업체 {id} - 필수 서류 업로드</h1>

                <div className="mb-6">
                    <nav className="flex space-x-4 border-b">
                        <button
                            onClick={() => setActiveTab('사업자 등록증')}
                            className={`px-4 py-2 ${activeTab === '사업자 등록증'
                                ? 'border-b-2 border-blue-500 text-blue-500'
                                : 'text-gray-500'
                                }`}
                        >
                            사업자 등록증
                        </button>
                        <button
                            onClick={() => setActiveTab('신분증')}
                            className={`px-4 py-2 ${activeTab === '신분증'
                                ? 'border-b-2 border-blue-500 text-blue-500'
                                : 'text-gray-500'
                                }`}
                        >
                            신분증
                        </button>
                        <button
                            onClick={() => setActiveTab('통장 사본')}
                            className={`px-4 py-2 ${activeTab === '통장 사본'
                                ? 'border-b-2 border-blue-500 text-blue-500'
                                : 'text-gray-500'
                                }`}
                        >
                            통장 사본
                        </button>
                    </nav>
                </div>

                <div className="bg-white p-8 rounded-lg shadow-lg">
                    {activeTab === '사업자 등록증' && (
                        <BusinessLicenseUpload vendorId={Number(id)} />
                    )}
                    {activeTab === '신분증' && (
                        <IDCardUpload vendorId={Number(id)} />
                    )}
                    {activeTab === '통장 사본' && (
                        <BankStatementUpload vendorId={Number(id)} />
                    )}

                    <div className="flex justify-center space-x-4 mt-8">
                        <button
                            onClick={handleCancel}
                            className="bg-teal-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-teal-600 transition duration-300 transform hover:scale-105"
                        >
                            목록으로 돌아가기
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyApprovalUpload;
