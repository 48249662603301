import React, { useState, useRef, useCallback } from 'react';
import Navbar from '../nav/Navbar';
import { vendors } from '../Example/ExampleCompany';

const orders = [
    { id: 12345, status: "준비 중", time: "14:32" },
    { id: 12346, status: "배달 중", time: "14:35" },
    { id: 12347, status: "완료", time: "14:40" },
    // 더 많은 주문들이 있다고 가정
    // ...
];

const Main: React.FC = () => {
    const [visibleOrders, setVisibleOrders] = useState(orders.slice(0, 5));
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);

    const lastOrderElementRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore) {
                    setVisibleOrders(prevOrders => {
                        const newOrders = orders.slice(prevOrders.length, prevOrders.length + 5);
                        if (newOrders.length === 0) {
                            setHasMore(false);
                        }
                        return [...prevOrders, ...newOrders];
                    });
                }
            });

            if (node) observer.current.observe(node);
        },
        [hasMore]
    );

    // 온라인 상태인 업체들 필터링
    const onlineVendors = vendors.filter(vendor => vendor.onlineStatus);
    // 오프라인 상태인 업체들 필터링
    const offlineVendors = vendors.filter(vendor => !vendor.onlineStatus);

    return (
        <div className="w-full h-screen flex flex-col overflow-hidden">
            <Navbar />
            <div className="flex-1 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6 p-6 overflow-hidden">
                {/* 왼쪽 섹션: 온라인 및 오프라인 상태인 업체들 */}
                <div className="md:w-1/3 flex flex-col space-y-6 overflow-hidden">
                    <div className="flex-1 overflow-hidden">
                        <h2 className="text-xl font-semibold mb-4">온라인 상태인 업체</h2>
                        <div className="max-h-full overflow-y-auto">
                            {onlineVendors.length > 0 ? (
                                <ul className="space-y-2">
                                    {onlineVendors.map(vendor => (
                                        <li key={vendor.id} className="p-3 bg-green-100 rounded-lg shadow text-sm">
                                            <span className="font-medium">{vendor.name}</span>
                                            <span className="ml-2 text-green-700">온라인</span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-sm">현재 온라인 상태인 업체가 없습니다.</p>
                            )}
                        </div>
                    </div>

                    <div className="flex-1 overflow-hidden">
                        <h2 className="text-xl font-semibold mb-4">오프라인 상태인 업체</h2>
                        <div className="max-h-full overflow-y-auto">
                            {offlineVendors.length > 0 ? (
                                <ul className="space-y-2">
                                    {offlineVendors.map(vendor => (
                                        <li key={vendor.id} className="p-3 bg-red-100 rounded-lg shadow text-sm">
                                            <span className="font-medium">{vendor.name}</span>
                                            <span className="ml-2 text-red-700">오프라인</span>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-sm">현재 오프라인 상태인 업체가 없습니다.</p>
                            )}
                        </div>
                    </div>
                </div>

                {/* 오른쪽 섹션: 실시간 주문 현황 및 오류/로그 */}
                <div className="md:w-2/3 flex flex-col space-y-6 overflow-hidden">
                    <div className="flex-1 p-4 bg-white rounded-lg shadow-md overflow-y-auto">
                        <h2 className="text-lg font-semibold mb-3">실시간 주문 현황</h2>
                        <div className="grid grid-cols-1 gap-2">
                            {visibleOrders.map((order, index) => {
                                if (index === visibleOrders.length - 1) {
                                    return (
                                        <div
                                            key={order.id}
                                            ref={lastOrderElementRef}
                                            className="p-3 bg-blue-100 rounded-lg shadow text-sm"
                                        >
                                            <h3 className="font-medium">주문 #{order.id}</h3>
                                            <p className="text-gray-600">상태: {order.status}</p>
                                            <p className="text-gray-600">시간: {order.time}</p>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={order.id} className="p-3 bg-blue-100 rounded-lg shadow text-sm">
                                            <h3 className="font-medium">주문 #{order.id}</h3>
                                            <p className="text-gray-600">상태: {order.status}</p>
                                            <p className="text-gray-600">시간: {order.time}</p>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                        {!hasMore && <p className="text-center mt-2 text-sm">더 이상 주문이 없습니다.</p>}
                    </div>

                    <div className="flex-1 p-4 bg-white rounded-lg shadow-md overflow-y-auto">
                        <h2 className="text-lg font-semibold mb-3">실시간 오류 및 로그</h2>
                        <ul className="space-y-2">
                            <li className="p-3 bg-red-100 rounded-lg shadow text-sm">
                                <p className="text-red-700">오류: 주문 #12345 - 결제 실패</p>
                                <p className="text-gray-600">시간: 14:30</p>
                            </li>
                            <li className="p-3 bg-yellow-100 rounded-lg shadow text-sm">
                                <p className="text-yellow-700">로그: 시스템 점검 완료</p>
                                <p className="text-gray-600">시간: 14:25</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
