import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../../nav/Navbar';
import { vendors as initialVendors, Vendor } from '../../Example/ExampleCompany';
import IDCardUpload from './ApprovalUpload/IDCardUpload';
import BankStatementUpload from './ApprovalUpload/BankStatementUpload';
import BusinessLicenseUpload from './ApprovalUpload/BusinessLicenseUpload';

// 사업자 등록번호 검증 함수
const validateBusinessNumber = (businessNumber: string): boolean => {
    if (businessNumber.length !== 10 || !/^\d{10}$/.test(businessNumber)) {
        return false;
    }

    const weights = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let sum = 0;

    for (let i = 0; i < 9; i++) {
        sum += parseInt(businessNumber[i]) * weights[i];
    }

    sum += Math.floor((parseInt(businessNumber[8]) * 5) / 10);
    const checkDigit = (10 - (sum % 10)) % 10;

    return checkDigit === parseInt(businessNumber[9]);
};

const CompanyApprovalDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [vendors, setVendors] = useState<Vendor[]>(initialVendors);
    const vendorIndex = vendors.findIndex(v => v.id === Number(id));
    const vendor = vendors[vendorIndex];

    const [name, setName] = useState(vendor?.name || "");
    const [businessNumber, setBusinessNumber] = useState(vendor?.businessNumber || "");
    const [status, setStatus] = useState(vendor?.status || "미승인");
    const [ownerName, setOwnerName] = useState(vendor?.ownerName || "");
    const [address, setAddress] = useState(vendor?.address || "");
    const [contactNumber, setContactNumber] = useState(vendor?.contactNumber || "");
    const [email, setEmail] = useState(vendor?.email || "");
    const [activeTab, setActiveTab] = useState('업체 정보 수정');

    if (vendorIndex === -1) {
        return <div>업체 정보를 찾을 수 없습니다.</div>;
    }

    const handleSaveInfo = () => {
        if (!validateBusinessNumber(businessNumber)) {
            alert('유효하지 않은 사업자 등록번호입니다.');
            return;
        }

        const updatedVendors = [...vendors];
        updatedVendors[vendorIndex] = {
            ...vendor,
            name,
            businessNumber,
            status,
            ownerName,
            address,
            contactNumber,
            email
        };

        setVendors(updatedVendors);

        alert(`업체 정보가 업데이트되었습니다:\n\n
        업체명: ${name}\n
        사업자 등록번호: ${businessNumber}\n
        상태: ${status}\n
        대표자명: ${ownerName}\n
        주소: ${address}\n
        연락처: ${contactNumber}\n
        이메일: ${email}`);

        navigate('/company-approval');
    };

    const handleCancel = () => {
        navigate('/company-approval');
    };

    return (
        <div className='w-full'>
            <Navbar />
            <div className="max-w-4xl mx-auto p-4">

                <h1 className="text-3xl font-bold mb-2">{name} - 세부 정보</h1>
                <p className="text-sm text-gray-500 mb-6">업체 고유 번호: {vendor.id}</p>

                {/* 탭 네비게이션 */}
                <div className="">
                    <nav className="flex space-x-4 border-b">
                        <button
                            onClick={() => setActiveTab('업체 정보 수정')}
                            className={`px-4 py-2 ${activeTab === '업체 정보 수정'
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'
                                }`}
                        >
                            업체 정보 수정
                        </button>
                        <button
                            onClick={() => setActiveTab('신분증 수정')}
                            className={`px-4 py-2 ${activeTab === '신분증 수정'
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'
                                }`}
                        >
                            신분증 수정
                        </button>
                        <button
                            onClick={() => setActiveTab('통장 사본 수정')}
                            className={`px-4 py-2 ${activeTab === '통장 사본 수정'
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'
                                }`}
                        >
                            통장 사본 수정
                        </button>
                        <button
                            onClick={() => setActiveTab('사업자 등록증 수정')}
                            className={`px-4 py-2 ${activeTab === '사업자 등록증 수정'
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'
                                }`}
                        >
                            사업자 등록증 수정
                        </button>
                    </nav>
                </div>

                {/* 탭 내용 */}
                {activeTab === '업체 정보 수정' && (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <div className="mt-4">
                            <label className="block font-semibold mb-2">업체명</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold mb-2">사업자 등록번호</label>
                            <input
                                type="text"
                                value={businessNumber}
                                onChange={(e) => setBusinessNumber(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold mb-2">대표자명</label>
                            <input
                                type="text"
                                value={ownerName}
                                onChange={(e) => setOwnerName(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold mb-2">주소</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold mb-2">연락처</label>
                            <input
                                type="text"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="mt-4">
                            <label className="block font-semibold mb-2">이메일</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* 권한에 따라 옵션 선택지를 다르게 보이게 설정 */}
                        <div className="mt-4">
                            <label className="block font-semibold mb-2">승인 상태</label>
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            >
                                <option value="미승인">미승인</option>
                                <option value="승인">승인</option>
                                <option value="승인 대기">승인 대기</option>
                                <option value="해지">해지</option>
                            </select>
                        </div>

                        <div className="flex justify-center space-x-4 mt-6">
                            <button
                                onClick={handleSaveInfo}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                            >
                                저장
                            </button>
                            <button
                                onClick={handleCancel}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                취소
                            </button>
                        </div>
                    </div>
                )}

                {activeTab === '신분증 수정' && (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <IDCardUpload vendorId={vendor.id} />
                    </div>
                )}

                {activeTab === '통장 사본 수정' && (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <BankStatementUpload vendorId={vendor.id} />
                    </div>
                )}

                {activeTab === '사업자 등록증 수정' && (
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <BusinessLicenseUpload vendorId={vendor.id} />
                    </div>
                )}
            </div>
        </div>

    );
};

export default CompanyApprovalDetail;
