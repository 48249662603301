import { eachMonthOfInterval, format, subDays, subMonths } from 'date-fns';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { vendorGrades, vendors } from '../../Example/ExampleCompany';
import { SalesData, vendorSalesData } from '../../Example/ExampleSalesData';
import Navbar from '../../nav/Navbar';
import SalesChart from './SalesData/SalesChart';
import SalesTable from './SalesData/SalesTable';
import Settlement from './Settlement';

interface Vendor {
    id: number;
    name: string;
    registrationDate: string;
    terminationDate?: string;
    grade: string;
}

const CompanySalesDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const vendorSales = vendorSalesData.find((sales) => sales.id === Number(id));
    const vendor = vendors.find((v) => v.id === Number(id));
    const [activeTab, setActiveTab] = useState<'매출 정보' | '계약 정보' | '정산'>('매출 정보');
    const [viewType, setViewType] = useState<'표로보기' | '차트로보기'>('표로보기');
    const [timeFrame, setTimeFrame] = useState<'일단위' | '주단위' | '월단위'>('일단위');

    if (!vendorSales || !vendor) {
        return <div>업체 정보를 찾을 수 없습니다.</div>;
    }

    const vendorGradeInfo = vendorGrades.find((grade) => grade.grade === vendor.grade);
    const commissionRate = vendorGradeInfo ? vendorGradeInfo.commissionRate : 0;

    const today = new Date();

    const getWeeklySalesData = (): SalesData[] => {
        const today = new Date();
        let currentEndDate = today;

        const weeklySales: SalesData[] = [];

        while (currentEndDate >= new Date(vendorSales.salesData[0].date)) {
            const weekStartDate = subDays(currentEndDate, 6);

            const weeklySalesTotal = vendorSales.salesData
                .filter(sales => new Date(sales.date) >= weekStartDate && new Date(sales.date) <= currentEndDate)
                .reduce((acc, curr) => acc + curr.dailySales, 0);

            weeklySales.push({
                date: `${format(weekStartDate, 'yyyy-MM-dd')} ~ ${format(currentEndDate, 'yyyy-MM-dd')}`,
                dailySales: weeklySalesTotal
            });

            currentEndDate = subDays(weekStartDate, 1);
        }

        return weeklySales.reverse(); // 최신 데이터가 아래로 가도록 역순으로 정렬
    };

    const getMonthlySalesData = (): SalesData[] => {
        const today = new Date();
        const startDate = new Date(today.getFullYear(), 0, 1); // 1월 1일 시작
        let currentEndDate = today;

        const monthlySales: SalesData[] = [];

        while (currentEndDate >= startDate) {
            const monthStartDate = new Date(currentEndDate.getFullYear(), currentEndDate.getMonth(), 1);

            const monthlySalesTotal = vendorSales.salesData
                .filter(sales => new Date(sales.date) >= monthStartDate && new Date(sales.date) < new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 1))
                .reduce((acc, curr) => acc + curr.dailySales, 0);

            monthlySales.push({
                date: format(monthStartDate, 'yyyy-MM'), // 여기서 'yyyy-MM' 형식으로 포맷
                dailySales: monthlySalesTotal
            });

            currentEndDate = subMonths(monthStartDate, 1);
        }

        return monthlySales.reverse(); // 최신 데이터가 아래로 가도록 역순으로 정렬
    };

    const filteredSalesData = (): SalesData[] => {
        if (timeFrame === '일단위') {
            return vendorSales.salesData;
        } else if (timeFrame === '주단위') {
            return getWeeklySalesData();
        } else if (timeFrame === '월단위') {
            return getMonthlySalesData();
        }
        return [];
    };

    const chartData = filteredSalesData().map((sales) => ({
        date: sales.date,
        value: sales.dailySales,
    }));

    return (
        <div className="w-full">
            <Navbar />
            <div className="max-w-6xl mx-auto">
                <div className="flex justify-between items-center m-4">
                    <h1 className="text-2xl font-bold">{vendor.name} - 상세 정보</h1>
                    <button
                        onClick={() => navigate('/company-sales')}
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                    >
                        업체 목록으로 돌아가기
                    </button>
                </div>

                <div className="">
                    <nav className="flex space-x-4 border-b">
                        <button
                            onClick={() => setActiveTab('매출 정보')}
                            className={`px-4 py-2 ${activeTab === '매출 정보' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                        >
                            매출 정보
                        </button>
                        <button
                            onClick={() => setActiveTab('계약 정보')}
                            className={`px-4 py-2 ${activeTab === '계약 정보' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                        >
                            계약 정보
                        </button>
                        <button
                            onClick={() => setActiveTab('정산')}
                            className={`px-4 py-2 ${activeTab === '정산' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'
                                }`}
                        >
                            정산
                        </button>
                    </nav>
                </div>

                {activeTab === '매출 정보' && (
                    <div>
                        <div className="flex space-x-4 my-4">
                            <button
                                onClick={() => setViewType('표로보기')}
                                className={`px-4 py-2 ${viewType === '표로보기' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } rounded-lg`}
                            >
                                표로보기
                            </button>
                            <button
                                onClick={() => setViewType('차트로보기')}
                                className={`px-4 py-2 ${viewType === '차트로보기' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } rounded-lg`}
                            >
                                차트로보기
                            </button>
                        </div>

                        <div className="flex space-x-4 mb-6">
                            <button
                                onClick={() => setTimeFrame('일단위')}
                                className={`px-4 py-2 ${timeFrame === '일단위' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } rounded-lg`}
                            >
                                일단위
                            </button>
                            <button
                                onClick={() => setTimeFrame('주단위')}
                                className={`px-4 py-2 ${timeFrame === '주단위' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } rounded-lg`}
                            >
                                주단위
                            </button>
                            <button
                                onClick={() => setTimeFrame('월단위')}
                                className={`px-4 py-2 ${timeFrame === '월단위' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                                    } rounded-lg`}
                            >
                                월단위
                            </button>
                        </div>

                        {viewType === '표로보기' ? (
                            <div className='max-h-96 overflow-y-auto'>
                                <SalesTable data={filteredSalesData()} commissionRate={commissionRate} />
                            </div>
                        ) : (
                            <SalesChart data={chartData} dataKey="value" title="매출 차트" />
                        )}
                    </div>
                )}

                {activeTab === '계약 정보' && (
                    <div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <p className="text-lg font-semibold mb-4">계약 시작일: {vendor.registrationDate}</p>
                            <p className="text-lg font-semibold mb-4">계약 종료일: {vendor.terminationDate || '계약 종료일 없음'}</p>
                            <p className="text-lg font-semibold mb-4">업체 등급: {vendor.grade}</p>
                            <p className="text-lg font-semibold mb-4">수수료율: {commissionRate}%</p>
                            <p className="text-lg">현재 적용 중인 계약 정보입니다.</p>
                        </div>
                    </div>
                )}

                {activeTab === '정산' && <Settlement vendorId={Number(id)} />}
            </div>
        </div>
    );
};

export default CompanySalesDetail;
