import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface SalesChartProps {
    data: Array<{ date: string; value: number }>;
    dataKey: string;
    title: string;
}

const SalesChart: React.FC<SalesChartProps> = ({ data, dataKey, title }) => {
    return (
        <div className="bg-white p-4 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold mb-4">{title}</h3>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis 
                        width={80} // Y축의 너비를 넓게 설정
                        tickFormatter={(value) => value.toLocaleString()} // Y축 값 포맷
                    />
                    <Tooltip formatter={(value: number) => [`${value.toLocaleString()} 원`]} />
                    <Line type="monotone" dataKey={dataKey} stroke="#8884d8" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SalesChart;
