import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vendorSalesData, VendorSales } from '../../Example/ExampleSalesData';
import { vendors, Vendor, vendorGrades } from '../../Example/ExampleCompany';

interface SettlementProps {
    vendorId: number;
}

const Settlement: React.FC<SettlementProps> = ({ vendorId }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const vendor: Vendor | undefined = vendors.find(v => v.id === vendorId);
    const salesData: VendorSales | undefined = vendorSalesData.find(sales => sales.id === vendorId);

    if (!vendor || !salesData) {
        return <div>업체 정보를 찾을 수 없습니다.</div>;
    }

    const vendorGradeInfo = vendorGrades.find(grade => grade.grade === vendor.grade);
    const commissionRate = vendorGradeInfo ? vendorGradeInfo.commissionRate : 0;

    // 마지막 정산 날짜 이후의 매출 데이터 필터링
    const lastSettlementDate = vendor.lastSettlementDate ? new Date(vendor.lastSettlementDate) : null;
    const filteredSalesData = lastSettlementDate
        ? salesData.salesData.filter(sale => new Date(sale.date) > lastSettlementDate)
        : salesData.salesData;

    const totalSales = filteredSalesData.reduce((acc, curr) => acc + curr.dailySales, 0);
    const totalCommission = totalSales * (commissionRate / 100);
    const totalSettlementAmount = totalSales - totalCommission;

    const handleSettlement = () => {
        setLoading(true);

        // 정산 로직 구현 (예: API 호출)
        setTimeout(() => {
            setLoading(false);
            alert(`${vendor.name}의 정산이 완료되었습니다. 총 정산 금액: ${totalSettlementAmount.toLocaleString()}원`);

            // 마지막 정산 날짜를 업데이트합니다 (여기서는 간단히 현재 날짜로 설정)
            vendor.lastSettlementDate = new Date().toISOString().split('T')[0];

            navigate('/company-sales');
        }, 1000);
    };

    return (
        <div className="w-full">
            <div className="mx-auto p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-2xl font-bold mb-4">{vendor.name} - 정산 페이지</h1>

                <div className="mb-6">
                    <p><strong>총 매출액:</strong> {totalSales.toLocaleString()}원</p>
                    <p><strong>수수료:</strong> {totalCommission.toLocaleString()}원</p>
                    <p><strong>총 정산 금액:</strong> {totalSettlementAmount.toLocaleString()}원</p>
                    <p><strong>마지막 정산 날짜:</strong> {vendor.lastSettlementDate || '정산 기록 없음'}</p>
                </div>

                <div className="flex justify-center space-x-4">
                    <button
                        onClick={handleSettlement}
                        disabled={loading}
                        className={`bg-blue-500 text-white px-4 py-2 rounded-md ${loading ? 'bg-opacity-50' : 'hover:bg-blue-600'} transition-colors duration-300`}
                    >
                        {loading ? '정산 중...' : '정산하기'}
                    </button>
                    <button
                        onClick={() => navigate('/company-sales')}
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition-colors duration-300"
                    >
                        취소
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Settlement;
