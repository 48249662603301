import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Main from './Main/Main';
import CompanyApproval from './Sub/CompanyApproval/CompanyApproval';
import CompanyApprovalDetail from './Sub/CompanyApproval/CompanyApprovalDetail';
import CompanySales from './Sub/CompanySales/CompanySales';
import CompanySalesDetail from './Sub/CompanySales/CompanySalesDetail';
import CompanyApprovalUpload from './Sub/CompanyApproval/CompanyApprovalUpload';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/company-approval" element={<CompanyApproval />} />
        <Route path="/company-approval/:id" element={<CompanyApprovalDetail />} />
        <Route path="/company-sales" element={<CompanySales />} />
        <Route path="/company-sales/:id" element={<CompanySalesDetail />} />
        <Route path="/company-approval/:id/upload" element={<CompanyApprovalUpload />} />
        {/* 다른 경로들 */}
      </Routes>
    </Router>
  );
};

export default App;
