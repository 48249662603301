// 업체별 신분증, 통장 사본, 사업자 등록증 정보 인터페이스
export interface VendorDocument {
    vendorId: number; // 업체 ID
    idCard: DocumentInfo | null; // 신분증 정보
    bankStatement: DocumentInfo | null; // 통장 사본 정보
    businessLicense: DocumentInfo | null; // 사업자 등록증 정보
}

// 각 서류 정보 인터페이스
export interface DocumentInfo {
    fileName: string; // 파일 이름
    fileType: string; // 파일 타입 (예: 'image/jpeg', 'application/pdf')
    uploadedAt: string; // 업로드 날짜 (ISO 8601 형식)
}

// 임시 데이터 생성
export const vendorDocuments: VendorDocument[] = [
    // 미승인 상태 업체들
    {
        vendorId: 1,
        idCard: null,
        bankStatement: {
            fileName: "bank_statement_1.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-02T11:00:00Z",
        },
        businessLicense: null,
    },
    {
        vendorId: 5,
        idCard: null,
        bankStatement: null,
        businessLicense: null,
    },
    {
        vendorId: 12,
        idCard: {
            fileName: "id_card_12.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-01T10:00:00Z",
        },
        bankStatement: null,
        businessLicense: {
            fileName: "business_license_12.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-03T12:00:00Z",
        },
    },
    
    // 승인 대기, 승인, 해지 상태 업체들
    {
        vendorId: 2,
        idCard: {
            fileName: "id_card_2.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-02T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_2.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-03T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_2.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-04T12:00:00Z",
        },
    },
    {
        vendorId: 3,
        idCard: {
            fileName: "id_card_3.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-03T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_3.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-04T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_3.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-05T12:00:00Z",
        },
    },
    {
        vendorId: 4,
        idCard: {
            fileName: "id_card_4.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-04T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_4.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-05T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_4.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-06T12:00:00Z",
        },
    },
    {
        vendorId: 6,
        idCard: {
            fileName: "id_card_6.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-06T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_6.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-07T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_6.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-08T12:00:00Z",
        },
    },
    {
        vendorId: 7,
        idCard: {
            fileName: "id_card_7.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-07T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_7.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-08T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_7.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-09T12:00:00Z",
        },
    },
    {
        vendorId: 8,
        idCard: {
            fileName: "id_card_8.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-08T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_8.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-09T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_8.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-10T12:00:00Z",
        },
    },
    {
        vendorId: 9,
        idCard: {
            fileName: "id_card_9.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-09T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_9.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-10T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_9.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-11T12:00:00Z",
        },
    },
    {
        vendorId: 10,
        idCard: {
            fileName: "id_card_10.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-10T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_10.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-11T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_10.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-12T12:00:00Z",
        },
    },
    {
        vendorId: 11,
        idCard: {
            fileName: "id_card_11.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-11T10:00:00Z",
        },
        bankStatement: {
            fileName: "bank_statement_11.pdf",
            fileType: "application/pdf",
            uploadedAt: "2024-08-12T11:00:00Z",
        },
        businessLicense: {
            fileName: "business_license_11.jpg",
            fileType: "image/jpeg",
            uploadedAt: "2024-08-13T12:00:00Z",
        },
    },
];
